import React, { useState } from "react";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import * as config from "./config.js";


const Login = React.lazy(() => import("./views/pages/login/Login"));
const TermCondition = React.lazy(() => import("./views/pages/termCondition/termCondition"));
const Dashboard = React.lazy(() => import("./views/pages/dashboard/dashboard"));
const Incentive = React.lazy(() => import("./views/pages/incentive/incentive"));
const VariablePay = React.lazy(() => import("./views/pages/variablePay/variablePay"));
const IncentiveDetails = React.lazy(() => import("./views/pages/incentive/incentiveDetails"));

const CompLetter = React.lazy(() => import("./views/pages/compLetters/compLetters"));
// const LetterDownlaod = React.lazy(() => import("./views/pages/letterDownload/LetterDownload"));
const LetterDownlaod = React.lazy(() => import("./views/pages/compLetters/letterDownload"));
const TotalRewardsStatment = React.lazy(() => import("./views/pages/totalRewStatement/trs"));

function App(props) {

  config.setConfig();
  return (
      // <Provider store={store}>
        <BrowserRouter>
          <React.Suspense fallback={""}>
            <div
              className={
                window.location.pathname == "/" ||
                  window.location.pathname == "/login"
                  ? ""
                  : "main"
              }
            >
              <Routes>
                <Route
                 exact ={true}
                  path={`${process.env.PUBLIC_URL}/login`}
                  name="Login"
                  element={<Login />}
                />
                 <Route
                 exact ={true}
                  path={`${process.env.PUBLIC_URL}/term_condition`}
                  name="Term condition"
                  element={<TermCondition />}
                />
                
                <Route
                 exact ={true}
                  path={`${process.env.PUBLIC_URL}/total-rewards-statment`}
                  name="Trs"
                  element={<TotalRewardsStatment />}
                />
                {/* /comp-review-letter */}
                <Route
                 exact ={true}
                  path={`${process.env.PUBLIC_URL}/`}
                  name="Login"
                  element={<Login />}
                />
                <Route
                 exact ={true}
                  path={`${process.env.PUBLIC_URL}/letter-download/:module/:user_id/:rule_id/:frequencyId/:assignment_id`}
                  name="Letter download"
                  element={<LetterDownlaod />}
                />
                  <Route
                 exact ={true}
                  path={`${process.env.PUBLIC_URL}/dashboard`}
                  name="Dashboard"
                  element={<Dashboard />}
                /> 
                 <Route
                exact ={true}
                 path={`${process.env.PUBLIC_URL}/comp-review-letter`}
                 name="Comp letter"
                 element={<CompLetter />}
               />
               
               <Route
                exact ={true}
                 path={`${process.env.PUBLIC_URL}/sip-emp-details/:rule_id/:frequency_id/:user_id`}
                 name="Incentive Details"
                 element={<IncentiveDetails />}
               />
                 <Route
                exact ={true}
                 path={`${process.env.PUBLIC_URL}/employee-variable-dtls`}
                 name="Incentive"
                 element={<VariablePay />}
               />
               <Route
                exact ={true}
                 path={`${process.env.PUBLIC_URL}/employee-incentive-list`}
                 name="Incentive"
                 element={<Incentive />}
               />
              </Routes>
            </div>
            {/* <NotificationContainer /> */}
          </React.Suspense>
        </BrowserRouter>
      // </Provider>
  );
}

export default App;
