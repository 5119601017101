import jwt_decode from 'jwt-decode';
import { callGetAPI } from './Common/api';
var token = localStorage.getItem("auth");


export function page_code() {
  return window.location.pathname.split("/")[1];
}

let config = {
  base_url: "",
};

let loggedin_user_info = {};
export function baseHelper() {
  return config["base_url"];
}

export function getId() {
  return loggedin_user_info["company_id"];
}

export async function getRoleId(page_name){
  let decoded1 = jwt_decode(token);
  let decoded = jwt_decode(decoded1.user_token);
  return decoded.role
}

export async function getDecoded(){
  let decoded1 = jwt_decode(token);
  return jwt_decode(decoded1.user_token);
}

export async function pageAccessAvailable(page_name){
  let isAccessible = await callGetAPI(`/check-rights-of-user/${page_name}`);
  if(!isAccessible || isAccessible.view_access==0){
    window.location.replace("/forbidden")
  }
  return isAccessible.write_access;
}

let userRoles = {};

export function checkToken() {
  return true;
}

export function setConfig() {
  if (window.location.origin.includes("localhost")) {
    config["base_url"] = "http://localhost:3005/api/v1";
  } else {
    config["base_url"] = window.location.origin + "/api/v1";
  }
}

export function setLoggedInUserInfo(data) {
  loggedin_user_info["user_id"] = data["ui"];
  loggedin_user_info["company_id"] = data["unit"];
  loggedin_user_info["user_email"] = data["det"];
  loggedin_user_info["role"] = data["role"];

}

export function setLoggedInUserRoles(data) {
  userRoles = data;
}


export default {
//   baseHelper,
//   checkToken,
  setConfig,
//   getRoleId,
//   pageAccessAvailable,
//   userRoles,
//   getDecoded,
};